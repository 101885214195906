import "./footer.scss";

export default function Footer(){
    return (
        
        <div className="footer">
           
            <div className='container'>
                
                    <p>Designed and built by William Nafack</p>
                    <p>Copyright ©2022 All rights reserved</p>
                    
            </div>
            
        </div>
      
    )}